import { useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Utils from "../../../controls/Utils";
// import AppContextComponent from "../../controls/AppContext";
import AppContextComponent from "./../../../controls/AppContext";

function LandingFooter() {
  let { setLoadingState, pageInfo, setPageInfo } =
    useContext(AppContextComponent);

  const { t } = useTranslation();
  const currentYear = new Date().getFullYear();

  // const onClickLinkHandler = (e) => {
  //   e.preventDefault();

  //   Utils.store(
  //     {
  //       path: Utils.PAGES[5],
  //     },
  //     setPageInfo,
  //   );
  // };

  return (
    <div className="certif-footer-wrapper d-flex">
      <div className="container d-flex">
        <div className="certif-footer-copyright-wrapper me-auto">
          <div className="certif-footer-copyright-info">
            © International SOS {currentYear}
          </div>
        </div>

        <div className="certif-footer-rightside-links-wrapper ms-auto">
          <div className="certif-footer-rightside-links d-flex">
          <div className="certif-footer-rightside-link">
              <a
                rel="noreferrer"
                href="https://www.internationalsos.com/policy"
                target="_blank"
              >
                <span>{t("policy")}</span>
              </a>
            </div>
            <div className="certif-footer-rightside-link">
              <a
                rel="noreferrer"
                href="https://www.internationalsos.com/privacy"
                target="_blank"
              >
                <span>{t("privacy")}</span>
              </a>
            </div>
            <div className="certif-footer-rightside-link">
              <Link
                rel="noreferrer"
                to={Utils.APP_URLS.TERMS_PAGE}
                // href="https://certificates.internationalsos.com/Pages/TermsAndConditions.aspx"
                target="_blank"
              // onClick={onClickLinkHandler}
              >
                <span>{t("termsAndConditions")}</span>
              </Link>
              {/* <Link to={Utils.APP_URLS.TERMS_PAGE}><span>Terms and Conditions</span></Link> */}
            </div>
            <div className="certif-footer-rightside-link">
              <a
                rel="noreferrer"
                href="https://www.internationalsos.com/en/"
                className="last"
                target="_blank"
              >
                <span>{t("aboutUs")}</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LandingFooter;
